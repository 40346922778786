@import "scss/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "scss/core/palette";
@import "scss/app-variables";
@import "scss/mixins";
@import "scss/custom-bootstrap";
@import "scss/custom-bootstrap-rtl";
@import "scss/core";
@import "scss/fonts/weather-icons";
@import "scss/plugins";
@import "scss/demo";  // Remove this SCSS from production