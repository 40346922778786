$wind-beaufort-0: "\f0b7";
$wind-beaufort-1: "\f0b8";
$wind-beaufort-2: "\f0b9";
$wind-beaufort-3: "\f0ba";
$wind-beaufort-4: "\f0bb";
$wind-beaufort-5: "\f0bc";
$wind-beaufort-6: "\f0bd";
$wind-beaufort-7: "\f0be";
$wind-beaufort-8: "\f0bf";
$wind-beaufort-9: "\f0c0";
$wind-beaufort-10: "\f0c1";
$wind-beaufort-11: "\f0c2";
$wind-beaufort-12: "\f0c3";