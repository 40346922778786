/* You can add global styles to this file, and also import other style files */

.loader-holder{ width:100%; height: 100%; position:fixed; left:0; top:0; bottom:0; background: rgba(0,0,0,0.6); z-index: 99999;}
.loader img{ object-fit:contain;position:absolute; margin:auto; width:150px; left:0; right:0; top:0%; bottom:0; }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.md-drppicker .btn.btn-default {
    margin-top:16px!important;
    margin-right:5px !important;
    color:white !important;
    background: red !important;

}

.md-drppicker .clear svg{
    display:none
}