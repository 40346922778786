.cal-day-view {
  .cal-hour-rows {
    width: 100%;
    border: solid 1px #e1e1e1;
    overflow-x: scroll;
    position: relative;
  }

  .cal-hour:nth-child(odd) {
    background-color: #fafafa;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  mwl-calendar-day-view-hour-segment, /* fix for https://github.com/mattlewis92/angular-calendar/issues/260*/
  .cal-hour-segment {
    display: block;
  }

  .cal-hour-segment::after {
    content: '\00a0';
  }

  .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed #e1e1e1;
  }

  .cal-time {
    font-weight: bold;
    padding-top: 5px;
    width: 70px;
    text-align: center;
  }

  .cal-hour-segment.cal-after-hour-start {
    .cal-time {
      display: none;
    }
  }

  .cal-hour-segment:hover,
  .cal-drag-over .cal-hour-segment {
    background-color: #ededed;
  }

  .cal-event-container {
    position: absolute;
  }

  .cal-event {
    padding: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    box-sizing: border-box;
    background-color: #d1e8ff;
    border: 1px solid #1e90ff;
    color: #1e90ff;
    user-select: none;
  }

  .cal-event-title:link {
    color: currentColor;
  }

  .cal-draggable {
    cursor: move;
  }

  .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .cal-all-day-event {
    padding: 8px;
    border: solid 1px;
  }
}
